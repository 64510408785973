const header = {
  homepage: '#',
  title: 'ba',
}

const about = {
  name: 'Braulio',
  role: 'Backend Engineer | Golang | Node',
  description:
    'Passionate backend developer with over 10 years of experience building robust solutions using Golang and Node.js. Extensive experience in creating and implementing Microservices, RESTful APIs and AWS deployments. Skilled in collaborating closely with multidisciplinary teams in agile environments to deliver high-quality software solutions.',
  resume: 'cv.pdf',
  social: {
    linkedin: 'https://linkedin.com/in/brauliodev',
    github: 'https://github.com/brauliusdev',
  },
}


const roles = [
  {
    name: 'Back-End',
    description: '',
    stack: ['Golang', 'Gin', 'Node.js', 'Nest.js', 'AWS Lambda', 'API REST', 'GraphQL', 'Microservice']
  },
  {
    name: 'Front-End',
    description: '',
    stack: ['Vue.js', 'Rect.js', 'JavaScript', 'Tailwind CSS', 'Hugo', 'HTML/CSS', 'Angular (legacy)']
  },
  {
    name: 'DevOps',
    description: '',
    stack: ['Docker', 'AWS', 'Git', 'SonarCloud', 'Terraform', 'GitHub Actions', 'Pipelines']
  },
  {
    name: 'Database',
    stack: ['PostgreSQL', 'MySQL', 'MariaDB', 'SQLite', 'MongoDB']
  },
  {
    name: 'Frameworks',
    stack: ['Gin', 'Echo', 'Fiber', 'Expres.js', 'Nest.js', 'Laravel', 'Symfony', 'Flask']
  },
  {
    name: 'Languajes',
    stack: ['Go', 'Node', 'Python (basic)', 'PHP', 'Rust (basic)']
  }
]

const contact = {
  email: 'brauliodev@mail.com',
  phone: 'https://wa.me/+525536678077?text=Hello'
}

export { header, about, roles, contact }
