import './Footer.css'

const Footer = () => {
  return (
    <footer className='footer'>
      <a href='#home' className='link footer__link'>
        brauliotech @ 2024
      </a>
    </footer>
  )
}

export default Footer;
